import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
const BOPDashboardRTMAnalyticsXYplotOne = React.lazy(
  () => import("pages/BOPDashboardRTMAnalyticsXYplotOne"),
);
const BOPDashboardRTMReadbacksXYplot = React.lazy(
  () => import("pages/BOPDashboardRTMReadbacksXYplot"),
);
const BOPDashboardFailurePropagationHydraulicFlow = React.lazy(
  () => import("pages/BOPDashboardFailurePropagationHydraulicFlow"),
);
const BOPDashboardOverviewdarkOne = React.lazy(
  () => import("pages/BOPDashboardOverviewdarkOne"),
);
const BOPDashboardRTMEventLoggerOne = React.lazy(
  () => import("pages/BOPDashboardRTMEventLoggerOne"),
);
const BOPDashboardRTMCycleCount = React.lazy(
  () => import("pages/BOPDashboardRTMCycleCount"),
);
const BOPDashboardRTMReadbacksGaugesOne = React.lazy(
  () => import("pages/BOPDashboardRTMReadbacksGaugesOne"),
);
const BOPDashboardRTMSensordata = React.lazy(
  () => import("pages/BOPDashboardRTMSensordata"),
);
const BOPDashboardOverview2 = React.lazy(
  () => import("pages/BOPDashboardOverview2"),
);
const BOPDashboardOverview = React.lazy(
  () => import("pages/BOPDashboardOverview"),
);
const BOPDashboardRTMReadbacksGauges = React.lazy(
  () => import("pages/BOPDashboardRTMReadbacksGauges"),
);
const BOPDashboardOverviewlightOne = React.lazy(
  () => import("pages/BOPDashboardOverviewlightOne"),
);
const BOPDashboardOverviewdark = React.lazy(
  () => import("pages/BOPDashboardOverviewdark"),
);
const BOPDashboardFailurePropagationCompliance = React.lazy(
  () => import("pages/BOPDashboardFailurePropagationCompliance"),
);
const BOPDashboardRTMEventLogger = React.lazy(
  () => import("pages/BOPDashboardRTMEventLogger"),
);
const OverviewTabThreeOne = React.lazy(
  () => import("pages/OverviewTabThreeOne"),
);
const BOPDashboardRTMReadbacksXYplotOne = React.lazy(
  () => import("pages/BOPDashboardRTMReadbacksXYplotOne"),
);
const BOPDashboardRTMAnalyticsXYplot = React.lazy(
  () => import("pages/BOPDashboardRTMAnalyticsXYplot"),
);
const BOPDashboardOverviewlight = React.lazy(
  () => import("pages/BOPDashboardOverviewlight"),
);
const OverviewTabThree = React.lazy(() => import("pages/OverviewTabThree"));
const RTMlogger = React.lazy(() => import("pages/RTMlogger"));
const RTMXYPlot = React.lazy(() => import("pages/RTMXYPlot"));
const RTMyclecounter = React.lazy(() => import("pages/RTMyclecounter"));
const OverviewTabOne = React.lazy(() => import("pages/OverviewTabOne"));
const OverviewTabTwo = React.lazy(() => import("pages/OverviewTabTwo"));
const OverviewPage = React.lazy(() => import("pages/Code/Overview/Overview"));
const CycleCounter = React.lazy(() => import("pages/Code/RTM/CycleCounter/CycleCounter"));
const XYPlot = React.lazy(() => import("pages/Code/RTM/XYPlot/XYPlot"));
const AlarmFailureLogger = React.lazy(() => import("pages/Code/RTM/AlarmFailureLogger/AlarmFailureLogger"));
const SensorData = React.lazy(() => import("pages/Code/RTM/SensorData/SensorData"));
const Readbacks = React.lazy(() => import("pages/Code/RTM/Readbacks/Readbacks"));
const ReadbacksGauges = React.lazy(() => import("pages/Code/RTM/Readbacks/Guages/Guages"));
const ReadbacksXYPlot = React.lazy(() => import("pages/Code/RTM/Readbacks/XYPlot/XYPlot"));
const Analytics = React.lazy(() => import("pages/Code/RTM/Analytics/Analytics"));
const AnalyticsCycleCount = React.lazy(() => import("pages/Code/RTM/Analytics/CycleCount/CycleCount"));
const AnalyticsXYPlot = React.lazy(() => import("pages/Code/RTM/Analytics/XYPlot/XYPlot"));
const EventLogger = React.lazy(() => import("pages/Code/RTM/EventLogger/EventLogger"));
const FailurePropagation = React.lazy(() => import("pages/Code/FailurePropagation/FailurePropagation/FailurePropagation"));
const HydraulicFlow = React.lazy(() => import("pages/Code/FailurePropagation/HydraulicFlow/HydraulicFlow"));
const FaultTree = React.lazy(() => import("pages/Code/FailurePropagation/FaultTree/FaultTree"));
const Compliance = React.lazy(() => import("pages/Code/FailurePropagation/Compliance/Compliance"));
const AlarmHistory = React.lazy(() => import("pages/Code/RTM/Analytics/AlarmHistory/AlarmHistory"));
const MultiYAxisPlot = React.lazy(() => import("pages/Code/RTM/Analytics/MultiYAxisPlot/MultiYAxisPlot"));
const PlotyMultiXYAxis = React.lazy(() => import("pages/Code/RTM/Analytics/PlotyMultiXYAxis/PlotyMultiXYAxis"));

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>
          {/* <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/overviewtabtwo" element={<OverviewTabTwo />} />
          <Route path="/overviewtabone" element={<OverviewTabOne />} />
          <Route path="/rtmyclecounter" element={<RTMyclecounter />} />
          <Route path="/rtmxyplot" element={<RTMXYPlot />} />
          <Route path="/rtmlogger" element={<RTMlogger />} />
          <Route path="/overviewtabthree" element={<OverviewTabThree />} /> */}
          
          {/* Custom Code */}
          <Route path="/" element={ <OverviewPage /> } />
          <Route path="/overview" element={ <OverviewPage /> } />
          <Route path="/rtm/cycle-counter" element={<CycleCounter />} />
          <Route path="/rtm/xy-plot" element={<XYPlot />} />
          <Route path="/rtm/alarm-failure-logger" element={<AlarmFailureLogger />} />
          <Route path="/rtm/sensor-data" element={<SensorData />} />
          <Route path="/rtm/event-data" element={<SensorData />} />
          <Route path="/rtm/riser-data" element={<SensorData />} />
          <Route path="/rtm/readbacks" element={<Readbacks />} />
          <Route path="/rtm/readbacks/guages" element={<ReadbacksGauges />} />
          <Route path="/rtm/readbacks/xy-plot" element={<ReadbacksXYPlot />} />
          <Route path="/rtm/analytics" element={<Analytics />} />
          <Route path="/rtm/analytics/cycle-count" element={<AnalyticsCycleCount />} />
          <Route path="/rtm/analytics/xy-plot" element={<AnalyticsXYPlot />} />
          <Route path="/rtm/analytics/alarm-history" element={<AlarmHistory />} />
          <Route path="/rtm/analytics/multi-y-axis-xy-plot" element={<MultiYAxisPlot />} />
          <Route path="/rtm/analytics/ploty-multi-xy-axis" element={<PlotyMultiXYAxis />} />
          <Route path="/rtm/event-logger" element={<EventLogger />} />

          <Route path="/failure-propagation/" element={<FailurePropagation />} />
          <Route path="/failure-propagation/hydraulic-flow" element={<HydraulicFlow />} />
          <Route path="/failure-propagation/fault-tree" element={<FaultTree />} />
          
          <Route path="/failure-propagation/compliance" element={<Compliance />} />





          {/* Custom Code */}
          <Route
            path="/bopdashboardoverviewlight"
            element={<BOPDashboardOverviewlight />}
          />
          <Route
            path="/bopdashboardrtmanalyticsxyplot"
            element={<BOPDashboardRTMAnalyticsXYplot />}
          />
          <Route
            path="/bopdashboardrtmreadbacksxyplotone"
            element={<BOPDashboardRTMReadbacksXYplotOne />}
          />
          <Route
            path="/overviewtabthreeone"
            element={<OverviewTabThreeOne />}
          />
          <Route
            path="/bopdashboardrtmeventlogger"
            element={<BOPDashboardRTMEventLogger />}
          />
          <Route
            path="/bopdashboardfailurepropagationcompliance"
            element={<BOPDashboardFailurePropagationCompliance />}
          />
          <Route
            path="/bopdashboardoverviewdark"
            element={<BOPDashboardOverviewdark />}
          />
          <Route
            path="/bopdashboardoverviewlightone"
            element={<BOPDashboardOverviewlightOne />}
          />
          <Route
            path="/bopdashboardrtmreadbacksgauges"
            element={<BOPDashboardRTMReadbacksGauges />}
          />
          <Route
            path="/bopdashboardoverview"
            element={<BOPDashboardOverview />}
          />
          <Route
            path="/bopdashboardoverview2"
            element={<BOPDashboardOverview2 />}
          />
          <Route
            path="/bopdashboardrtmsensordata"
            element={<BOPDashboardRTMSensordata />}
          />
          <Route
            path="/bopdashboardrtmreadbacksgaugesone"
            element={<BOPDashboardRTMReadbacksGaugesOne />}
          />
          <Route
            path="/bopdashboardrtmcyclecount"
            element={<BOPDashboardRTMCycleCount />}
          />
          <Route
            path="/bopdashboardrtmeventloggerone"
            element={<BOPDashboardRTMEventLoggerOne />}
          />
          <Route
            path="/bopdashboardoverviewdarkone"
            element={<BOPDashboardOverviewdarkOne />}
          />
          <Route
            path="/bopdashboardfailurepropagationhydraulicflow"
            element={<BOPDashboardFailurePropagationHydraulicFlow />}
          />
          <Route
            path="/bopdashboardrtmreadbacksxyplot"
            element={<BOPDashboardRTMReadbacksXYplot />}
          />
          <Route
            path="/bopdashboardrtmanalyticsxyplotone"
            element={<BOPDashboardRTMAnalyticsXYplotOne />}
          />
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
